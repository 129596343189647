.widget {
    position: fixed;
    bottom: 25px;
    right: 25px;
    width: 240px;
    height: 135px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, .3);
    overflow: hidden;
    z-index: 99;
    animation: mymove 5s;

    @keyframes mymove {
        from {
            transform: translateX(calc(100% + 25px));
        }

        20% {
            transform: translateX(calc(100% + 25px));
        }

        to {
            transform: translateX(0);
        }
    }
}

.widget .swiper {
    width: 100%;
    height: 100%;
}

.widget__content {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 17px;
    width: 100%;
    height: 100%;
    background-size: cover;
    cursor: grab;

    &--slide_1 {
        background-image: url('/images/widget/img_1.webp');
    }

    &--slide_2 {
        background-image: url('/images/widget/img_2.webp');
    }
}

.widget__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(0deg, rgba(48, 53, 55, 0.9) 0%, rgba(48, 53, 55, 0.792) 35.42%, rgba(48, 53, 55, 0) 100%);
    opacity: 0.4;
}

.widget__link,
a.widget__link {
    display: block;
    margin-top: auto;
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    line-height: 1.23;
    color: #fff;
    text-decoration: none;
    text-shadow: 0 0 10px #000;
    z-index: 1;
    cursor: pointer;

    &:hover {
        text-decoration: none;
    }
}

.widget__close {
    position: absolute;
    top: 17px;
    right: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, .2);
    cursor: pointer;

    &:before {
        content: "\2715";
        font-size: 19.5px;
        line-height: 30px;
        color: #fff;
    }
}