.portfolio-sections {}

.portfolio-section {
    margin-top: 30px;
}

.portfolio-section__anchor {
    position: relative;
    top: -100px;
    content: " ";
    display: block;
    width: 1px;
    height: 1px;
}

.portfolio-section__header {
    font-family: "Roboto", sans-serif;
    font-weight: lighter;
    color: #919191;
    font-size: 20px;
    margin-bottom: 20px;
}

.portfolio-section__slider {
    width: 100%;
    height: auto;

    .swiper {
        width: 100%;
        height: auto;
        --swiper-theme-color: #fff;
        --swiper-navigation-top-offset: 22px;
        --swiper-navigation-sides-offset: 0;

        @include bp(600px) {
            --swiper-navigation-top-offset: 15px;
            --swiper-navigation-size: 30px;
        }
    }

    .swiper-slide {
        width: 100%;
        height: auto;

        img {
            width: 100%;
        }
    }

    a.swiper-slide {
        line-height: 14px;
        text-decoration: none;
    }

    .swiper-slide-label {
        height: 46px;

        .swiper-slide-label__name {
            display: block;
            padding-top: 10px;
            border-top: 3px solid #f14214;
            font-family: "Tahoma", "PT Sans", sans-serif;
            font-size: 12px;
            color: #414040;
            text-align: center;
            white-space: nowrap;
        }

        .swiper-slide-label__more {
            display: block;
            margin-top: 5px;
            font-family: "Tahoma", "PT Sans", sans-serif;
            font-size: 12px;
            text-align: center;
            text-decoration: underline;
            white-space: nowrap;
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        padding: calc(50% * 350 / 670) 30px;

        &:hover {
            background-color: rgba(255, 255, 255, 0.2);
        }
    }
}

.portfolio__slider {
    margin-bottom: 20px;
    width: 100%;
    height: auto;

    @include bp(600px) {
        margin-bottom: 10px;
    }

    .swiper {
        width: 100%;
        height: auto;
        --swiper-theme-color: #fff;
        --swiper-navigation-top-offset: 22px;
        --swiper-navigation-sides-offset: 0;

        @include bp(600px) {
            --swiper-navigation-top-offset: 15px;
            --swiper-navigation-size: 30px;
        }
    }

    .swiper-slide {
        width: 100%;
        height: auto;
        border-bottom: 3px solid #f14214;

        img {
            width: 100%;
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        padding: calc(50% * 350 / 670) 30px;

        &:hover {
            background-color: rgba(255, 255, 255, 0.2);
        }
    }
}

.portfolio__thumbs {
    margin-bottom: 20px;
    width: 100%;
    height: auto;

    .swiper {
        width: 100%;
        height: auto;
        --swiper-theme-color: #fff;
        --swiper-navigation-top-offset: 22px;
        --swiper-navigation-sides-offset: 0;
    }

    .swiper-slide {
        max-width: 152px;
        height: auto;
        border-bottom: 3px solid transparent;
        opacity: 0.9;

        &:hover {
            border-bottom: 3px solid #f14214;
        }

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }

    .swiper-slide-thumb-active {
        border-bottom: 3px solid #f14214;
        opacity: 1;
    }
}