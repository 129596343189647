.aside {
    padding-top: 30px;
    padding-bottom: 30px;
    width: 215px;
}

.aside__header {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    font-weight: lighter;
    line-height: 20px;
    color: #474846;
}

.aside__block-header {
    margin: 10px auto 5px auto;

    & a {
        font-family: "Roboto", sans-serif;
        font-size: 12px;
        color: #f04113;
        text-decoration: none;
    }
}

.aside__block-list {
    margin: 5px auto 10px auto;
    padding-left: 20px;
    line-height: 14px;
    list-style: none;

    & li {
        margin-bottom: 5px;
    }

    & a {
        font-family: "Roboto", sans-serif;
        font-size: 11px;
        font-weight: lighter;
        text-decoration: none;
        color: #808080;
    }

    & a.cur {
        color: #f04113;
    }
}

.aside__blocks-list {
    list-style: none;
    padding-left: 0;
    margin: 5px auto 10px auto;
    line-height: 14px;

    & li {
        margin-bottom: 15px;
        position: relative;
        padding-right: 30px;
    }

    & a {
        font-family: "Roboto", sans-serif;
        font-size: 12px;
        color: #f04113;
        text-decoration: none;
    }
}

.aside__blocks-count {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    color: #808080;
    font-weight: lighter;
    text-decoration: none;
    position: absolute;
    right: 0;
    top: 0;
}