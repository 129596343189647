.article {

    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: "Roboto", sans-serif;
        font-weight: lighter;
        line-height: 1.23;
        color: #919191;
    }



    .h1,
    h1 {
        font-size: 30px;
        color: #f14214;

        @include bp(600px) {
            font-size: 25px;
        }
    }


    .h2,
    h2 {
        font-size: 20px;
    }

    .h3,
    .h4,
    .h5,
    .h6,
    h3,
    h4,
    h5,
    h6 {
        font-size: 15px;
    }
}

.alert {
    margin: 10px 0;
    padding: 20px;
    border: 1px solid #cccccc;
}

.alert-danger {
    color: #f14214;
    border: 1px solid #f14214;
}

.alert-success {
    color: #1db129;
    border: 1px solid #1db129;
}

.small.text-danger {
    margin: 0;
    font-size: 10px;
    color: #f14214;
}

.blockquote {
    padding: 15px 25px;
    border: 1px solid #dedede;
    font-size: 11px;
    line-height: 20px;
    color: #626262;
}