.subnav {
    position: absolute;
    top: calc(100% + 3px);
    width: 100%;
    background: url('/images/sixbox/shadow_six.png') top center no-repeat #FFF;
    z-index: 220;

    @include bp(1000px) {
        display: none;
    }

    &.opened {
        border-bottom: 3px solid #e6e6e6;
        box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.10);
    }

    &.sl {
        top: 73px;
        position: fixed;
    }
}

.subnav-block {
    display: none;
    padding-top: 20px;
    padding-bottom: 30px;
    width: 100%;

    .subnav-block__columns {
        margin-top: 30px;
        column-count: 3;
        column-width: 230px;
        column-gap: 50px;

        @include bp(900px) {
            column-gap: 25px;
        }
    }

    &.opened {
        display: block;
        z-index: 230;
    }
}

.subnav-block__close {
    position: absolute;
    top: 30px;
    right: 30px;
    background: url('/images/icons/icon_subnav_x.png') no-repeat;
    width: 15px;
    height: 15px;
    color: transparent;
    content: "";
    font-size: 0;
    display: block;
    opacity: 0.5;

    &:hover {
        opacity: 1;
    }
}

.subnav-block__header {
    color: #f14214;
    font-size: 11px;
    font-family: 'Tahoma', "PT Sans", sans-serif;

    & .h2 {
        color: #adadad;
        font-family: 'Roboto', sans-serif;
        font-weight: lighter;
        font-size: 18px;
        display: inline;
        margin-right: 20px;
    }
}

.subnav-column+.subnav-column {
    margin-top: 20px;
}

.subnav-column__header {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;

    & a {
        color: #f04113;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    &--gray {
        padding: 10px 0 10px 0;
    }

    // &--blog {
    //     background: url('/images/icons/icon_blog.png') no-repeat top left;
    //     padding: 10px 0 10px 36px;
    //     border-bottom: 1px solid #dedede;
    //     margin: 0 0 10px 0;
    // }

    // &--portfolio {
    //     background: url('/images/icons/icon_portfolio.png') no-repeat 0 3px;
    //     padding: 10px 0 10px 36px;
    //     border-bottom: 1px solid #dedede;
    //     margin: 0 0 10px 0;

    //     & a {
    //         color: #5c5c5c;
    //     }
    // }
}

.subnav-column__list-menu {
    padding: 0 0 0 13px;
    margin-top: 10px;
    margin-bottom: 20px;
    border-left: 1px solid #dedede;
    list-style: none;

    & li {
        top: -2px;
        position: relative;
        padding: 0 0 2px 0;
        line-height: 18px;

        &:last-of-type {
            padding: 0;
        }
    }

    & li a {
        color: #414040;
        font-family: 'Tahoma', "PT Sans", sans-serif;
        font-size: 12px;
        text-decoration: none;

        &:hover {
            color: #f04113;
        }
    }
}

.subnav-column__text {
    color: #414040;
    font-family: 'Tahoma', "PT Sans", sans-serif;
    font-size: 12px;
    line-height: 18px;
    border-top: 1px solid #dadada;
    padding-top: 10px;
    margin-bottom: 10px;

    &--addr {
        color: #787878;
        padding-bottom: 10px;
    }

    &--contacts {
        color: #414040;
        font-family: 'Tahoma', "PT Sans", sans-serif;
        font-size: 12px;
        line-height: 18px;
        padding-top: 10px;
        margin-bottom: 10px;
    }
}


.subnav-blog-block__item {
    border-bottom: 1px dotted #b8b7b7;
    margin: 0 0 15px 0;
}

.subnav-blog-block__item a {
    color: #787878;
    font-family: 'Arial', sans-serif;
    font-style: italic;
    font-size: 12px;
    text-decoration: none;
    padding-right: 10px;
    background: #ffffff;
    position: relative;
    top: 4px;
}

.subnav-blog-block__item-date {
    color: #b8b7b7;
    font-family: 'Arial', sans-serif;
    font-style: italic;
    font-size: 12px;
    background: #ffffff;
    position: relative;
    top: 4px;
    padding: 0 5px 0 0;
    white-space: nowrap;
}

.subnav-portfolio {
    width: 269px;
    height: 172px;
    overflow: hidden;
    position: relative;
}

.subnav-portfolio__in {
    width: 3300px;
    position: relative;
    left: 0;
}

.subnav-portfolio__item {
    float: left;
    width: 269px;
    height: 172px;
    margin-right: 10px;
}

.subnav-porfolio__image {
    padding: 1px;
    border: 1px solid #cbcbcb;
}

.subnav-column__porfolio-text {
    font-family: 'Roboto', sans-serif;
    font-weight: lighter;
    color: #adadad;
    font-size: 18px;
    margin: 10px 0 0 0;
}

.subnav-column__porfolio-text a {
    text-decoration: none;
}

.subnav-column__porfolio-arrows {
    position: relative;
    height: 16px;
    margin: 5px 0 5px 0;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    text-align: center;
}

.subnav-column__porfolio-arrows_left {
    width: 8px;
    height: 16px;
    background: url('/images/icons/arrow_small_red_left.png') no-repeat;
    background-position: 50% 50%;
    padding: 5px 10px;
    content: "";
    font-size: 0;
    color: transparent;
    display: block;
    margin: 0 5px 0 0;
    cursor: pointer;
    position: absolute;
    left: 100px;
}

.subnav-column__porfolio-arrows_right {
    width: 8px;
    height: 16px;
    background: url('/images/icons/arrow_small_red_right.png') no-repeat;
    background-position: 50% 50%;
    padding: 5px 10px;
    content: "";
    font-size: 0;
    color: transparent;
    display: block;
    margin: 0 0 0 5px;
    cursor: pointer;
    position: absolute;
    left: 150px;
}


.subnav-blocks__block_in {
    margin: 0 auto;
    width: 940px;
    padding: 0 0 30px 0;
    position: relative;
}

.subnav-blocks__block--about.opened .subnav-blocks__block_in::before {
    /*content: '';
    position: absolute;
    left: 550px;
    top: -20px;
    border: 10px solid transparent;
    border-bottom: 10px solid #f14214;*/
}

.subnav-blocks__block--zhd.opened .subnav-blocks__block_in::before {
    /*content: '';
    position: absolute;
    left: 660px;
    top: -20px;
    border: 10px solid transparent;
    border-bottom: 10px solid #f14214;*/
}


.subnav-blocks__block--scheme.opened .subnav-blocks__block_in::before {
    /*content: '';
    position: absolute;
    left: 785px;
    top: -20px;
    border: 10px solid transparent;
    border-bottom: 10px solid #f14214;*/
}

.subnav-blocks__block--contacts.opened .subnav-blocks__block_in::before {
    /*content: '';
    position: absolute;
    left: 895px;
    top: -20px;
    border: 10px solid transparent;
    border-bottom: 10px solid #f14214;*/
}