.form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 16px;
    max-width: 680px;
}

.form-header {
    color: #f14214;
    font-size: 30px;
    font-family: "Roboto", sans-serif;
    font-weight: lighter;
}

.form-row {
    display: flex;
    justify-content: space-between;
    column-gap: 30px;

    @include bp(600px) {
        flex-direction: column;
        row-gap: 12px;
    }
}

.form-column {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.form-control {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.form-column,
.form-control {
    width: 100%;

    &--25 {
        width: 25%;
    }

    &--33 {
        width: 33%;
    }

    &--50 {
        width: 50%;
    }

    &--67 {
        width: 67%;
    }

    &--75 {
        width: 75%;
    }

    &--25,
    &--33,
    &--50,
    &--67,
    &--75 {
        @include bp(600px) {
            width: 100%;
        }
    }
}

.form-label {
    font-family: "Tahoma", sans-serif;
    font-size: 12px;
    color: #414040;
}

.form-input {
    display: block;
    width: 100%;
    padding: 7px 10px;
    border: 1px solid #cacaca;
}

.form-checkbox-wrapper {
    display: flex;
    align-items: flex-start;
    column-gap: 8px;
}

.form-checkbox {
    margin-top: 3.5px;
    margin-bottom: 3.5px;
}

.form-textarea {
    display: block;
    padding: 7px 10px;
    width: 100%;
    height: 92px;
    border: 1px solid #cacaca;
    resize: vertical;
}

.has-error .form-input {
    border: 1px solid #ef4012;
}

.has-error .help-block {
    display: none;
}

.form-close {
    right: 20px;
    background: url('/images/icons/icon_subnav_x.png') no-repeat;
    width: 15px;
    height: 15px;
    color: transparent;
    content: "";
    font-size: 0;
    display: block;
    opacity: 0.5;
}

.form-close:hover {
    opacity: 1;
}

.form-info {
    border: 1px solid #dedede;
    padding: 20px 25px;
    color: #737373;
    font-family: "Tahoma", sans-serif;
    font-size: 11px;
    line-height: 18px;
    margin: 30px auto;
}

.form-musthave {
    color: #cc0000;
    margin-left: 5px;
    font-family: "Tahoma", sans-serif;
}

.form-submit {
    background-color: #ef4012;
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    color: #ffffff;
    font-size: 14px;
    cursor: pointer;
}

.login-form {
    width: 300px;
}

.form-errors {
    padding: 20px;
    color: #ef4012;
    border: 1px solid #ef4012;

    & ul {
        list-style: none;
        margin: 0;
        padding: 0;

        & li+li {
            margin-top: 5px;
        }
    }
}

.form-group {
    margin-bottom: 1rem;
}

.label {
    margin-bottom: .5rem;
    display: block;
    font-size: 12px;
    color: #414040;
    font-family: "Tahoma", sans-serif;
}

.error-notify {
    color: #dc3545;
    font-size: 80%;
    font-weight: 400;
    line-height: 1.5;
}

.input {
    display: block;
    padding: 7px 10px;
    border: 1px solid #cacaca;
    width: 100%;
    box-sizing: border-box;
}

.button {
    display: block;
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    color: #ffffff;
    font-size: 14px;
    background-color: #ef4012;
    cursor: pointer;
    width: 100%;
}


.input-file__label {
    display: flex;
    align-items: flex-start;
}

.input-file__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    border: 1px solid #cacaca;
}

.input-file__text {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.input-file__info {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
}

.input-file__notice {
    font-size: 12px;
    line-height: 1.2;
}