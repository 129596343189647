.main-content__announce {
    font-family: 'Roboto', sans-serif;
    font-weight: lighter;
    font-size: 24px;
    text-align: center;
    padding: 40px 10px;

    @include bp(600px) {
        padding: 40px 0px;
        font-size: 18px;
    }
}

.main-content__title {
    font-size: 36px;
    font-family: 'Roboto', sans-serif;
    font-weight: lighter;
    text-align: center;

    &>h2,
    &>span {
        display: inline;
    }

    h2 {
        font-weight: 700;
    }

    @include bp(800px) {
        font-size: 28px;
    }

    @include bp(600px) {
        font-size: 20px;
    }
}

.main-content-row {
    display: flex;
    justify-content: space-between;
    column-gap: 100px;
    margin-top: 50px;

    @include bp(950px) {
        column-gap: 50px;
    }

    @include bp(800px) {
        flex-direction: column;
    }
}

.main-content-column {
    width: 50%;

    @include bp(850px) {
        width: 100%;
    }
}

.main-content-column__item {
    position: relative;
    margin-bottom: 30px;
    padding-left: 70px;
    min-height: 70px;
    list-style: none;
    font-family: 'Roboto', sans-serif;
    font-weight: lighter;
    font-size: 18px;
    line-height: 1.6;
    background: url('/images/main/icon_ok.png') 0 6px no-repeat;

    h3 {
        display: inline-block;
        font-weight: 700;
    }

    @include bp(950px) {
        padding-left: 60px;
        background-size: 35px;
    }

    @include bp(850px) {
        margin-bottom: 20px;
        padding-left: 50px;
        font-size: 16px;
        line-height: 1.4;
        background-size: 30px;
    }
}

.main-content-column__item-link {
    display: block;
    position: absolute;
    top: -10px;
    bottom: -10px;
    left: -15px;
    right: -15px;
    border-radius: 4px;
    font-size: 0;
    opacity: 0.2;
    cursor: pointer;
    z-index: 1;

    &:hover {
        background-color: #bdbdbd;
    }
}

.main-content-simple {
    padding: 30px 40px;
    font-family: 'Roboto', sans-serif;
    font-weight: lighter;
    text-align: center;
    font-size: 24px;
    margin: 20px 0 70px 0;

    @include bp(600px) {
        padding: 30px 0px;
        font-size: 20px;
    }
}

.main-section__container--inhider {
    display: none;
}