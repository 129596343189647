.blackback {
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 220;
    display: none;
}

.upflash {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 700px;
    padding: 50px;
    // left: 50%;
    // margin-left: -400px;
    // top: 220px;
    background: #ffffff;
    z-index: 400;
    display: none;
}

// .upflash-bigimage {
//     width: 940px;
//     margin-left: -520px;
// }

.apply-form {
    display: none;
}


.modal {
    position: fixed;
    top: -1000%;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 40px;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 0;
    overflow-y: auto;
    z-index: 100;
    cursor: pointer;
    transition: opacity .2s ease-in;

    &.active {
        top: 0;
        opacity: 1;
    }

    @include bp(595px) {
        padding: 0;
    }
}

.modal__content {
    position: relative;
    margin: 0 auto;
    padding: 40px 45px;
    max-width: 600px;
    background-color: #fff;
    cursor: default;

    &--center {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        @include bp(595px) {
            left: 0;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
        }
    }

    &--right {
        position: absolute;
        right: 0;
        top: 0;
        padding: 40px 45px;
        max-width: 600px;
        min-height: 100%;
    }
}

.modal-content__title {
    margin-bottom: 20px;
    text-align: center;
}

.modal-content__text p+p {
    margin-top: 10px;
}

.modal-content__btn {
    margin-top: 25px;
}

.modal__close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    transform: rotate(45deg);
    cursor: pointer;

    & svg {
        stroke: #000;
    }
}