.mobile-subnav {
    position: absolute;
    display: none;
    top: calc(100% + 3px);
    width: 100%;
    background: url('/images/sixbox/shadow_six.png') top center no-repeat #FFF;
    z-index: 220;
    display: none;
    padding-top: 20px;
    width: 100%;

    &.opened {
        @include bp(1000px) {
            position: fixed;
            top: 135px;
            left: 0;
            right: 0;
            bottom: 100px;
            overflow-y: auto;
            display: block;
            padding-bottom: 20px;
            // box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.10);
            overflow-y: scroll;
            z-index: 230;

            @include bp(600px) {
                top: 92.5px;
            }

            .mobile-subnav-contacts {
                position: fixed;
                bottom: 0;
                height: 100px;
                left: 0;
                right: 0;
                background-color: #fff;
                box-shadow: 0 10px 20px 20px rgba(0, 0, 0, 0.15);
                border-bottom: 3px solid #e6e6e6;
                padding: 10px 30px;
                border-top: 1px solid lightgray;
            }
        }
    }

    &.sl {
        top: 73px;
        position: fixed;
    }
}

.mobile-subnav-block__close {
    position: absolute;
    top: 30px;
    right: 30px;
    background: url('/images/icons/icon_subnav_x.png') no-repeat;
    width: 15px;
    height: 15px;
    color: transparent;
    content: "";
    font-size: 0;
    display: block;
    opacity: 0.5;

    &:hover {
        opacity: 1;
    }
}

.mobile-subnav-block__header {
    color: #f14214;
    font-size: 11px;
    font-family: 'Tahoma', "PT Sans", sans-serif;

    & a {
        text-decoration: none;
    }

    & .h2 {
        color: #adadad;
        font-family: 'Roboto', sans-serif;
        font-weight: lighter;
        font-size: 18px;
        display: inline;
        margin-right: 20px;
    }
}

.mobile-subnav-block__columns {
    margin-top: 15px;
    // column-count: 3;
    column-width: 230px;
    column-gap: 50px;

    @include bp(900px) {
        column-gap: 25px;
    }
}

.mobile-subnav-column+.mobile-subnav-column {
    margin-top: 15px;
}

.mobile-subnav-column__header {
    display: flex;
    align-items: baseline;
    column-gap: 5px;

    margin-top: 8px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;

    & a {
        color: #f04113;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    &--gray {
        padding: 10px 0 10px 0;
    }
}


.mobile-subnav-column__header-arrow {
    padding-left: 20px;
    margin-left: -20px;
    cursor: pointer;
    // background-color: aquamarine;
}


.mobile-subnav-column__list-menu {
    padding: 0 0 0 13px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-left: 1px solid #dedede;
    list-style: none;

    & li {
        top: -2px;
        position: relative;
        padding: 0 0 2px 0;
        line-height: 18px;

        &:last-of-type {
            padding: 0;
        }
    }

    & li a {
        color: #414040;
        font-family: 'Tahoma', "PT Sans", sans-serif;
        font-size: 12px;
        text-decoration: none;

        &:hover {
            color: #f04113;
        }
    }
}

.mobile-subnav-column__text {
    color: #414040;
    font-family: 'Tahoma', "PT Sans", sans-serif;
    font-size: 12px;
    line-height: 18px;
    border-top: 1px solid #dadada;
    padding-top: 10px;
    margin-bottom: 10px;

    &--addr {
        color: #787878;
        padding-bottom: 10px;
    }

    &--contacts {
        color: #414040;
        font-family: 'Tahoma', "PT Sans", sans-serif;
        font-size: 12px;
        line-height: 18px;
        padding-top: 10px;
        margin-bottom: 10px;
    }
}

.mobile-subnav-contacts {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 10px;
}

.mobile-subnav__phones {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
}