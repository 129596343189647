.article {
    padding-top: 30px;
    padding-bottom: 90px;
    font-family: "Tahoma", "PT Sans", sans-serif;
    font-size: 12px;
    line-height: 1.67;
    letter-spacing: 0.02em;
    color: #414040;
}

.article__title {
    margin-bottom: 20px;
}

.article__avatar {
    margin-bottom: 20px;
    width: 100%;
    border-bottom: 3px solid #f14214;

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.article__content {
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    &--min-height {
        min-height: 400px;
    }

    ul {
        padding-left: 25px;

        li {
            position: relative;
            list-style: none;

            &:before {
                content: "";
                width: 5px;
                height: 5px;
                position: absolute;
                left: -20px;
                top: 10px;
                background-color: #f14214;
                border-radius: 5px;
            }
        }
    }

    ol {
        counter-reset: list_item;
        margin: 30px auto;

        li {
            position: relative;
            display: block;
            padding-left: 20px;
            min-height: 42px;
            margin-bottom: 15px;
            line-height: 20px;

            &:before {
                content: counter(list_item);
                counter-increment: list_item;
                position: absolute;
                width: 31px;
                height: 37px;
                left: -31px;
                top: -5px;
                z-index: -1;
                font-size: 17px;
                color: #ffffff;
                line-height: 37px;
                vertical-align: middle;
                font-family: "Roboto", sans-serif;
                font-weight: lighter;
                text-align: center;
                background: url('/images/icons/list_item_bg.png') no-repeat 0 0;
            }
        }
    }

    .article__append {
        text-align: justify;
        border-left: 3px solid #f14214;
        padding: 10px 0 10px 20px;
    }

    .article__links {
        display: flex;
        flex-flow: row wrap;
        column-gap: 50px;
        row-gap: 30px;

        &--mobile {
            display: none;

            @include bp(800px) {
                display: flex;
            }
        }

        @include bp(600px) {
            display: flex;
            flex-flow: column nowrap;
        }
    }

    .article__links .article__links-column {
        flex: 1;

        &--mobile {
            display: none;

            @include bp(800px) {
                display: block;
            }
        }
    }

    .article__links .h2 {
        margin-bottom: 10px;
    }
}