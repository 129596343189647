html {
    margin: 0;
    padding: 0;
    font-family: "PT Sans", sans-serif;
    font-size: 14px;
    color: #414040;
}

body {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}

.container {
    margin: 0 auto;
    padding: 0 30px;
    width: 100%;
    max-width: 1000px;

    @include bp(600px) {
        padding: 0 20px;
    }

    &--flex {
        display: flex;
        justify-content: space-between;
        column-gap: 55px;
    }
}

.column {
    &--main {
        max-width: 930px;
        width: calc(100% - 215px - 55px);

        @include bp(800px) {
            width: 100%;
        }
    }

    &--aside {
        flex-shrink: 0;

        @include bp(800px) {
            display: none;
        }
    }
}

.no-scroll {
    overflow: hidden;
}

.visually-hidden {
    opacity: 0;
    visibility: hidden;
    width: 0px;
    height: 0px;
    display: block;
    // margin-left: -10000px;
    background: transparent;
    font-size: 0;
}