$line-width: 30px;
$line-height: 3px;
$color: #f14214;

$width: $line-width;
$spacer: 8px;
$height: 2 * $spacer + 3 * $line-height;

.mobile-nav-icon {
    width: $width;
    height: $height;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.mobile-nav-icon__middle {
    position: relative;
    width: $line-width;
    height: $line-height;
    background-color: $color;
    transition: background-color 0s linear .2s;

    &::before,
    &::after {
        content: "";
        position: absolute;
        left: 0;
        width: $line-width;
        height: $line-height;
        background-color: $color;
        transition: transform .2s ease-in,
            top .2s linear .2s;
    }

    &::before {
        top: -$spacer ;
    }

    &::after {
        top: $spacer;
    }
}

.mobile-nav-icon.opened {
    @include bp(1000px) {}

    @include bp(600px) {
        // position: fixed;
        // top: 35px;
        // right: 25px;
    }

}

.mobile-nav-icon.opened .mobile-nav-icon__middle {
    background-color: transparent;

    &::before,
    &::after {
        top: 0;
        // background-color: #FFF;
        transition: top .2s linear,
            transform .2s ease-in .2s;
    }

    &::before {
        transform: rotate(45deg);
    }

    &::after {
        transform: rotate(-45deg);
    }
}