.main-map {
    margin-bottom: -100px;
    padding-top: 0;
    padding-bottom: 100px;
    background: url('/images/main/main_map.png') no-repeat 50% -80px;
}

.main-map__content {
    font-family: 'robofanregular';
    font-weight: normal;
    height: 310px;
    font-family: 'robofanregular';
    line-height: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0;
}

.main-map__title {

    &>h1,
    &>span {
        display: inline;
    }
}

.main-map__title-primary {
    display: block;
    margin-top: -20px;
    margin-bottom: 9px;
    padding: 0;
    font-size: 88px;
    color: #000000;

    @include bp(800px) {
        font-size: 76px;
    }

    @include bp(600px) {
        font-size: 64px;
    }

    @include bp(450px) {
        font-size: 40px;
    }
}

.main-map__title-secondary {
    display: inline;
    font-size: 24px;
    line-height: 1.08;
    color: #afafaf;

    @include bp(800px) {
        font-size: 22px;
    }

    @include bp(600px) {
        font-size: 17px;
    }

    &--accent {
        display: inline;
        border-bottom: 2px solid transparent;
        color: #f14214;
        text-decoration: none;

        &:hover {
            color: #f14214;
        }
    }

    &--add {
        white-space: nowrap;

        @include bp(450px) {
            white-space: unset;
        }
    }
}