.header {
    position: relative;
    flex-shrink: 0;
    padding-top: 40px;
    padding-bottom: 20px;
    border-bottom: 3px solid #f14214;

    @include bp(600px) {
        padding-top: 23px;
    }

    &.vh {
        visibility: hidden;
    }
}

.header__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header__logo {
    padding-bottom: 10px;
    font-size: 0;

    @include bp(600px) {
        padding-bottom: 0;
    }
}

.header__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    row-gap: 10px;

    @include bp(1000px) {
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        column-gap: 12px;
        row-gap: unset;
    }
}

.header__contacts {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 7px;
}

.header__phones {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #bdbdbd;
    font-size: 14px;
    font-family: 'PT Sans', sans-serif;

    @include bp(700px) {
        display: none;
    }
}

.header__phone-main,
.header__phone-add {
    span {
        color: #6b6b6b;
    }
}

.header__icons-desktop {
    display: flex;
    align-items: center;
    column-gap: 4px;
    color: #bdbdbd;
    font-size: 14px;
    font-family: 'PT Sans', sans-serif;
    font-size: 0;

    @include bp(700px) {
        display: none;
    }
}

.header__social-icon {
    width: 21px;
    height: 21px;
    display: block;
    color: transparent;
    font-size: 0;
    text-indent: -1000px;
    content: "";
    opacity: 0.6;

    &:hover {
        opacity: 1;
    }
}

.header__icons-mobile {
    display: none;
    font-size: 0;

    @include bp(700px) {
        display: flex;
        align-items: center;
        column-gap: 6px;
    }
}


.header__nav {
    display: flex;
    justify-content: flex-end;

    @include bp(1000px) {
        display: none;
    }
}

.header__mobile-nav {
    display: none;

    @include bp(1000px) {
        display: block;
    }
}