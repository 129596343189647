.content-gallery {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;

    @include bp(900px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @include bp(800px) {
        grid-template-columns: repeat(4, 1fr);
    }

    @include bp(600px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @include bp(420px) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.content-gallery__item {
    width: 100%;
    height: auto;
    border-bottom: 3px solid #ffffff;
    cursor: pointer;

    &:hover {
        border-bottom: 3px solid #f14214;
    }

    &.cur {
        border-bottom: 3px solid #f14214;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}