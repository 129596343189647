a.logo {
    display: block;
    width: 236px;
    height: 62px;
    font-size: 0;

    @include bp(600px) {
        width: 177px;
        height: auto;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}