.order-button,
a.order-button {
    display: block;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 20px 20px 20px 80px;
    border: 3px solid #f14214;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: lighter;
    text-decoration: none;
    color: #f14214;
    background: url('/images/icons/aside_form.png');
    background-repeat: no-repeat;
    background-position: 20px 20px;

    &:hover,
    &:visited {
        text-decoration: none;
    }
}