.main-portfolio {
    border-top: 3px solid #f14214;
}

.main-portfolio__title {
    font-family: 'robofanregular';
    font-weight: normal;

    font-size: 30px;
    text-align: center;
    padding: 30px 0 20px 0;
    color: #191919;

    @include bp(600px) {
        font-size: 25px;
    }
}

.main-portfolio__items-wrapper {
    position: relative;
    margin: auto;
    max-width: 1920px;
    height: 165px;
    overflow: hidden;
}

.main-portfolio__items {
    position: absolute;
    top: 0;
    left: 50%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    transform: translateX(-50%);
}

.main-portfolio__item {
    margin-left: -1px;
    width: 165px;
    height: 165px;
    transform: skewX(-60deg);
    overflow: hidden;
}

.main-portfolio__item-image {
    position: relative;
    left: -150px;
    display: block;
    width: 500px;
    height: 165px;
    background-position: 0 165px;
    font-size: 0;
    transform: skewX(60deg);

    &:hover {
        background-position: 0 0;
    }

    &--1 {
        background-image: url('/images/main/portfolio/1.jpg');
    }

    &--2 {
        background-image: url('/images/main/portfolio/2.jpg');
    }

    &--3 {
        background-image: url('/images/main/portfolio/3.jpg');
    }

    &--4 {
        background-image: url('/images/main/portfolio/4.jpg');
    }

    &--5 {
        background-image: url('/images/main/portfolio/5.jpg');
    }

    &--6 {
        background-image: url('/images/main/portfolio/6.jpg');
    }

    &--7 {
        background-image: url('/images/main/portfolio/7.jpg');
    }

    &--8 {
        background-image: url('/images/main/portfolio/8.jpg');
    }

    &--9 {
        background-image: url('/images/main/portfolio/9.jpg');
    }

    &--10 {
        background-image: url('/images/main/portfolio/10.jpg');
    }

    &--11 {
        background-image: url('/images/main/portfolio/11.jpg');
    }

    &--12 {
        background-image: url('/images/main/portfolio/12.jpg');
    }
}

.main-portfolio__link {
    padding: 20px;
    text-align: center;
    font-size: 11px;

    a {
        color: #f14214;
    }
}