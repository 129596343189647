.icon {
    display: block;
    flex-shrink: 0;

    &--phone,
    &--email {
        width: 32px;
        height: 32px;
        fill: #bdbdbd;

        &:hover {
            fill: #f14214;
        }
    }

    &--whatsapp,
    &--telegram {
        width: 28px;
        height: 28px;
        fill: #bdbdbd;

        &:hover {
            fill: #f14214;
        }
    }

    &--arrow-down {
        width: 14px;
        height: 8px;
        stroke: #f14214;
        stroke-width: 3px;
    }

    &--plus {
        width: 12px;
        height: 12px;
        stroke: #f14214;
    }
}